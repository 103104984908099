import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useUserContext } from "../../context/UserContext";
import { SubscriptionStatus } from "../../reclaim-api/team/Team";
import { ReclaimEdition } from "../../reclaim-api/Users";
import { useTeam } from "./useTeam";

const upsells = [
  "unlimited active habits",
  "unlimited calendar syncs",
  "unlimited Smart 1:1s",
  "custom decompression event titles",
  "all third-party integrations",
];

const subscriptionAtom = atom<SubscriptionStatus | null>(null);
const daysLeftInTrialAtom = atom<number | null>(null);
const upsellAtom = atom<string>(upsells[Math.floor(Math.random() * upsells.length)]);

export type TrialExpirationContext = {
  subscriptionStatus: SubscriptionStatus | undefined;
  daysLeftInTrial: number | undefined;
  upsell: string;
  pendingClawback: boolean;
};

export const useTrialExpiration = (): TrialExpirationContext => {
  const {
    team,
    userMembership,
    actions: { load },
  } = useTeam();

  const [{ user }] = useUserContext();

  const [subscriptionStatus, setSubscriptionStatus] = useAtom(subscriptionAtom);
  const [daysLeftInTrial, setDaysLeftInTrial] = useAtom(daysLeftInTrialAtom);
  const [upsell] = useAtom(upsellAtom);

  const pendingClawback = userMembership?.edition === ReclaimEdition.Free && user?.edition !== ReclaimEdition.Free;

  useEffect(() => {
    void setSubscriptionStatus(team?.subscriptionStatus || null);
    const userMember = team?.members.find((member) => !!user?.id && member.teamMemberId?.userId === user?.id);

    if (!userMember?.trialEnd) return;

    // Don't show if edition isn't trial
    if (userMember?.edition === ReclaimEdition.Reward) return;
    // Don't show if edition after trial is team plan
    if (userMember?.editionAfterTrial === ReclaimEdition.Team) return;

    const expMs = userMember.trialEnd.getTime();

    // Only show if trialEnd is upcoming
    if (expMs <= new Date().getTime()) {
      void setDaysLeftInTrial(null);
      return;
    }

    function updateDays(): void {
      const days = Math.ceil((expMs - new Date().getTime()) / (1000 * 60 * 60 * 24));
      void setDaysLeftInTrial(days);
    }

    const interval = setInterval(updateDays, 1000 * 60 * 60);
    updateDays();

    () => clearInterval(interval);
  }, [team, user?.id, setDaysLeftInTrial, setSubscriptionStatus]);

  useEffect(() => {
    if (!team) {
      void load();
    }
  }, [team, load]);

  return {
    subscriptionStatus: subscriptionStatus || undefined,
    daysLeftInTrial: daysLeftInTrial || undefined,
    upsell,
    pendingClawback,
  };
};
