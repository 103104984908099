import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { defer } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState, VFC } from "react";
import { FieldValues, useForm } from "react-hook-form";
import {
  dataToValues,
  getDefaultPickerHourForUserPolicy,
  newTaskFormValues,
  TaskFormValidators,
  TaskFormValues,
  valuesToData,
} from ".";
import { useUserContext } from "../../../context/UserContext";
import { useOurRouter } from "../../../hooks/useOurRouter";
import { useShortcut } from "../../../hooks/useShortcut";
import NoteIcon from "../../../img/note-icon.svg";
import { Calendar } from "../../../reclaim-api/Calendars";
import { PrimaryCategory } from "../../../reclaim-api/EventMetaTypes";
import { getUserDefaultDueDate, getUserDefaultSnoozeUntil, makeDefaultTask, Task } from "../../../reclaim-api/Tasks";
import { TimePolicyType } from "../../../reclaim-api/Users";
import {
  DATE_TIME_DISPLAY_FORMAT,
  getFormattedDisplayDate,
  isDateNowish,
  parseRouteDate,
  snap,
} from "../../../utils/dates";
import { platform } from "../../../utils/platform";
import { MicroFormLayout } from "../../global-add/MicroFormLayout";
import { MicroFormSection } from "../../global-add/Section";
import { Tooltip } from "../../Tooltip";
import { AsyncButton } from "../AsyncButton";
import { DateControl } from "../rhf/DateControl";
import { DurationControl, DurationControlValidators } from "../rhf/DurationControl";
import { EmojiTextFieldControl } from "../rhf/EmojiTextFieldControl";
import { RhfForm } from "../rhf/RhfForm";
import { TextFieldControl } from "../rhf/TextFieldControl";

const useStyles = makeStyles((theme) => ({
  titleWrap: {
    marginBottom: theme.spacing(2),
    "& .MuiFormHelperText-root": {
      margin: 0,
      paddingLeft: "2.5rem",
    },
  },
  titleTextInput: {
    ...theme.typography.h5,
    fontWeight: theme.typography.fontWeightMedium,
  },
  emojiIcon: {
    flex: 0,
    marginRight: theme.spacing(1),
    minWidth: 24,
    paddingTop: 10,
    color: theme.colors.primary,
    display: "flex",
    fontSize: 16,
    height: 24,
    justifyContent: "center",
    width: 24,
  },
  defaultEmoji: {
    height: 18,
    width: 18,
    margin: "2px 0 0 -1px",
  },
  calendarIcon: {},
  clockIcon: {
    marginTop: -2,
    padding: 1,
  },
  flexTime: {
    display: "flex",
    "&:first-child": {
      marginTop: -2,
    },
    "&:not(:last-child)": {
      marginBottom: theme.spacing(0.5),
    },
  },
  inputAdornment: {
    ...theme.typography.body1,
    marginRight: theme.spacing(1),
    whiteSpace: "nowrap",
    paddingBottom: 2,
    color: theme.colors.black,
  },
  timeInput: {
    flex: 1,
    width: "100%",
    "& .MuiFormHelperText-root": {
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      "& input": {
        fontSize: 16, //must be at least 16px so that Safari doesn't zoom in on iPhones
      },
    },
  },
  timeTextInput: {
    color: theme.colors.primary,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 1,
  },
  input: {
    paddingTop: 3,
    lineHeight: theme.typography.body1.fontSize,
    height: theme.typography.body1.fontSize,
    "&:focus": {
      "&::placeholder": {
        opacity: 0.6,
      },
    },
    "&::placeholder": {
      color: theme.colors.grey,
      opacity: 1,
    },
  },
  categoryLabel: {
    marginBottom: 3,
    marginTop: 1,
  },
  categoryToggle: {
    fontSize: 15,
    padding: theme.spacing(0, 1, 0.25),
  },
  noteIcon: {
    height: 19,
    width: 19,
    margin: 0,
    padding: 0,
  },
  notesTextInput: {
    lineHeight: 1.5,
    margin: "-6px 0 -7px", //offset input padding
    maxHeight: 200,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16, //must be at least 16px so that Safari doesn't zoom in on iPhones
    },
  },
  eyeIcon: {
    height: 18,
    width: 18,
  },
  infoIcon: {
    margin: theme.spacing(0, 0, 0.25, 0.75),
    opacity: 0.4,
    verticalAlign: "middle",
    cursor: "pointer",
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

export type MicroTaskFormProps = {
  data: Partial<Task> | null;
  primaryCalendar?: Calendar;
  onSave: (data: Partial<Task>) => Promise<void>;
  onChange?: (data: Partial<Task>) => void;
};

export const MicroTaskForm: VFC<MicroTaskFormProps> = ({ data, primaryCalendar, onSave, onChange }) => {
  const classes = useStyles();
  const microFormLayoutRef = useRef<HTMLDivElement>();
  const router = useOurRouter<{ date?: string }>();

  const [{ user }] = useUserContext();

  const [saving, setSaving] = useState<boolean>(false);

  const dates = useMemo(() => {
    const contextualDate = parseRouteDate(router.query.date) || new Date();
    return snap(contextualDate, "week");
  }, [router.query.date]);

  const defaultFormValues: FieldValues = useMemo(
    () => dataToValues(makeDefaultTask(user, dates.start)),
    [user, dates.start]
  );

  const form = useForm<TaskFormValues, object>({
    defaultValues: dataToValues(data, defaultFormValues),
    mode: "onSubmit",
  });

  // Warning: Must use form.getValues() for any field validation. The watch values are still
  // stale when validation is triggered
  const watch = form.watch();

  const titleRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  useShortcut(
    "Tab",
    useCallback(
      (e) => {
        if (submitButtonRef.current && submitButtonRef.current === document.activeElement && !e.shiftKey) {
          e.preventDefault();
          form.setFocus("title");
        }
      },
      [form]
    )
  );

  useShortcut(
    ["Shift", "Tab"],
    useCallback((e) => {
      if (!!titleRef.current && titleRef.current === document.activeElement) {
        e.preventDefault();
        submitButtonRef.current?.focus();
      }
    }, [])
  );

  const inputProps = useMemo(
    () => ({
      disableUnderline: true,
      className: classes.timeTextInput,
      classes: { input: classes.input },
    }),
    [classes]
  );

  const categoryLabel = useMemo(
    () => (PrimaryCategory.Personal.key === watch.eventCategory ? "personal" : "working"),
    [watch.eventCategory]
  );

  const timePolicy =
    watch.eventCategory === PrimaryCategory.SoloWork.key ? TimePolicyType.Work : TimePolicyType.Personal;

  const privacyDetails = useMemo(() => {
    if (watch.alwaysPrivate) return { title: "Private", tooltip: "This task will be made private." };

    switch (primaryCalendar?.accessDomainRead) {
      case true:
        return {
          title: "Visible to others",
          tooltip:
            "Details can be seen by anyone with access to your calendar, including coworkers in the same email domain.",
        };
      case false:
        return {
          title: "Private",
          tooltip:
            "Details can only be seen by those with access to your calendar. Check your Google Calendar sharing settings to see who has access.",
        };
      default:
        return {
          title: "Possibly visible to others",
          tooltip: "Task events will have the same visibility as any other default calendar events.",
        };
    }
  }, [watch.alwaysPrivate, primaryCalendar]);

  const privacyIcon = useMemo(
    () =>
      watch.alwaysPrivate ? (
        <VisibilityOffOutlinedIcon className={classes.eyeIcon} fontSize="inherit" />
      ) : (
        <VisibilityOutlinedIcon className={classes.eyeIcon} fontSize="inherit" />
      ),
    [watch.alwaysPrivate, classes.eyeIcon]
  );

  const snoozeUntilPlaceholder = useMemo(() => {
    const snooze = !!user ? getUserDefaultSnoozeUntil(user, timePolicy) : new Date();
    return isDateNowish(snooze) ? "now" : getFormattedDisplayDate(snooze, DATE_TIME_DISPLAY_FORMAT);
  }, [user, timePolicy]);

  const dueDatePlaceholder = useMemo(() => {
    const due = !!user ? getUserDefaultDueDate(user, timePolicy) : null;
    return due === null ? "anytime" : getFormattedDisplayDate(due, DATE_TIME_DISPLAY_FORMAT);
  }, [user, timePolicy]);

  const handleDefaultSnoozePickerHour = useCallback(
    (date: Date): Date => getDefaultPickerHourForUserPolicy(user, date, timePolicy, "start"),
    [user, timePolicy]
  );

  const handleDefaultDuePickerHour = useCallback(
    (date: Date): Date => getDefaultPickerHourForUserPolicy(user, date, timePolicy, "end"),
    [user, timePolicy]
  );

  const setTitleRef = useCallback((el: HTMLInputElement) => {
    titleRef.current = el;
  }, []);

  const validateMinChunkSizeMax = useCallback(
    (v: string) =>
      TaskFormValidators.maximumMinChunkSize(v, form.getValues().maxChunkSize || defaultFormValues.maxChunkSize),
    [form, defaultFormValues.maxChunkSize]
  );

  const handleCategoryChange = useCallback(() => {
    form.setValue(
      "eventCategory",
      watch.eventCategory === PrimaryCategory.Personal.key ? PrimaryCategory.SoloWork.key : PrimaryCategory.Personal.key
    );
  }, [form, watch.eventCategory]);

  const handlePrivacyChange = useCallback(() => {
    form.setValue("alwaysPrivate", !watch.alwaysPrivate);
  }, [form, watch.alwaysPrivate]);

  const resetForm = useCallback(async () => {
    // Note: The actions are sequenced here in a specific way. We want to prevent a change
    // event from firing on the reset and we want to set the focus after the form in enabled.
    await form.reset(newTaskFormValues(defaultFormValues));
    setSaving(false);
    form.setFocus("title");
    // Errors will appear on fresh form without a deferred error clear.
    defer(() => form.clearErrors("title"));
  }, [form, defaultFormValues]);

  const handleSubmit = useCallback(
    async (values: TaskFormValues) => await onSave(valuesToData(values, defaultFormValues)),
    [defaultFormValues, onSave]
  );

  const handleErrors = useCallback(() => {
    setSaving(false);
    throw new Error("Tasks: Unable to create.");
  }, []);

  const handleSubmitClick = useCallback(async () => {
    setSaving(true);
    return form.handleSubmit(handleSubmit, handleErrors)().then(resetForm).catch(handleErrors);
  }, [form, resetForm, handleSubmit, handleErrors]);

  useEffect(() => {
    const subscription = form.watch((values) => {
      if (onChange && !saving) {
        onChange(valuesToData(values));
      }
    });

    return () => subscription.unsubscribe();
  }, [form, saving, onChange]);

  return (
    <RhfForm form={form} onSubmit={handleSubmitClick} submitOnShortcut>
      <MicroFormLayout
        ref={microFormLayoutRef}
        endOfUnusedAdornment={
          <Tooltip title={`${platform().isMac ? "⌘" : "Ctrl"} + Enter to submit`}>
            <AsyncButton
              ref={submitButtonRef}
              aria-label="Save"
              variant="contained"
              size="small"
              color="primary"
              disabled={saving}
              onClick={handleSubmitClick}
              onError={(err) => console.warn(err)}
            >
              Save
            </AsyncButton>
          </Tooltip>
        }
      >
        {/* Title */}
        <Box className={classes.titleWrap}>
          <EmojiTextFieldControl
            name="title"
            placeholder="New Task"
            autoFocus
            required="Task title is required"
            iconClassName={classes.emojiIcon}
            defaultEmojiClassName={classes.defaultEmoji}
            disabled={saving}
            setInputRef={setTitleRef}
            InputProps={{
              disableUnderline: true,
              className: classes.titleTextInput,
            }}
          />
        </Box>

        {/* Scheduling */}
        <MicroFormSection
          id="time"
          title="Time Needed"
          iconActionTitle={"Date & Time"}
          icon={<ScheduleRoundedIcon className={classes.clockIcon} fontSize="small" />}
          open
        >
          {/* Time Required */}
          <Box className={classes.flexTime}>
            <DurationControl
              name="timeChunksRequired"
              aria-label="Total time"
              size="small"
              variant="standard"
              className={classes.timeInput}
              placeholder={defaultFormValues.timeChunksRequired}
              InputProps={{
                startAdornment: <Typography className={classes.inputAdornment}>I need</Typography>,
                ...inputProps,
              }}
              disabled={saving}
              rules={{
                validate: {
                  interval: (v: string) => DurationControlValidators.interval(v, "15 min"),
                },
              }}
            />
          </Box>

          {/* Category */}
          <Box className={classes.flexTime}>
            <Typography variant="body1" className={classes.categoryLabel}>
              during my
              <Button
                className={classes.categoryToggle}
                color="primary"
                variant="text"
                component="a"
                disabled={saving}
                title={`Category: ${categoryLabel}`}
                onClick={handleCategoryChange}
              >
                {categoryLabel}
              </Button>
              hours
            </Typography>
          </Box>

          {/* Min Chunk */}
          <Box className={classes.flexTime}>
            <DurationControl
              name="minChunkSize"
              aria-label="At least"
              variant="standard"
              className={classes.timeInput}
              InputProps={{
                startAdornment: <Typography className={classes.inputAdornment}>in time blocks between</Typography>,
                ...inputProps,
              }}
              disabled={saving}
              placeholder={defaultFormValues.minChunkSize}
              rules={{
                validate: {
                  interval: (v: string) => DurationControlValidators.interval(v, "15 min"),
                  max: validateMinChunkSizeMax,
                },
              }}
            />
          </Box>

          {/* Max Chunk */}
          <Box className={classes.flexTime}>
            <DurationControl
              name="maxChunkSize"
              aria-label="No more than"
              variant="standard"
              className={classes.timeInput}
              InputProps={{
                startAdornment: <Typography className={classes.inputAdornment}>and</Typography>,
                ...inputProps,
              }}
              placeholder={defaultFormValues.maxChunkSize}
              disabled={saving}
              rules={{
                validate: {
                  interval: (v: string) => DurationControlValidators.interval(v, "15 min"),
                },
              }}
              onBlur={() => form.trigger(["minChunkSize"])}
            />
          </Box>
        </MicroFormSection>

        {/* start/due */}
        <MicroFormSection
          id="due"
          title="Start/due"
          icon={<CalendarTodayOutlinedIcon className={classes.calendarIcon} fontSize="inherit" />}
          open
        >
          {/* Start Date */}
          <Box className={classes.flexTime}>
            <DateControl
              name="snoozeUntil"
              aria-label="Scheduled start"
              variant="standard"
              format={DATE_TIME_DISPLAY_FORMAT}
              containerClassName={classes.flexGrow}
              className={classes.timeInput}
              placeholder={snoozeUntilPlaceholder}
              defaultDatePickerHour={handleDefaultSnoozePickerHour}
              InputProps={{
                startAdornment: <Typography className={classes.inputAdornment}>between</Typography>,
                ...inputProps,
              }}
              disabled={saving}
              chronoOptions={{ forwardDate: true }}
            />
          </Box>

          {/* Due Date */}
          <Box className={classes.flexTime}>
            <DateControl
              name="due"
              aria-label="Due date"
              variant="standard"
              format={DATE_TIME_DISPLAY_FORMAT}
              containerClassName={classes.flexGrow}
              className={classes.timeInput}
              placeholder={dueDatePlaceholder}
              defaultDatePickerValue={defaultFormValues.due}
              defaultDatePickerHour={handleDefaultDuePickerHour}
              InputProps={{
                startAdornment: <Typography className={classes.inputAdornment}>and</Typography>,
                ...inputProps,
              }}
              disabled={saving}
              chronoOptions={{ forwardDate: true }}
            />
          </Box>
        </MicroFormSection>

        {/* notes */}
        <MicroFormSection
          id="notes"
          title="Notes"
          icon={<NoteIcon className={classes.noteIcon} />}
          open={!!watch.notes}
        >
          <TextFieldControl
            name="notes"
            fullWidth
            multiline
            placeholder="Add notes here... markdown too"
            disabled={saving}
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              className: classes.notesTextInput,
              style: {
                overflow: "auto",
              },
            }}
          />
        </MicroFormSection>

        {/* Privacy */}
        <MicroFormSection
          id="privacy"
          title={privacyDetails.title}
          iconActionTitle="Toggle visibility"
          isToggle
          canTabIcon
          icon={privacyIcon}
          open={false}
          onIconClick={handlePrivacyChange}
        >
          <Typography variant="body1" style={{ marginTop: 2 }}>
            {privacyDetails.title}
            <Tooltip
              placement="top"
              title={
                <Typography variant="body2" display="block">
                  {privacyDetails.tooltip}
                </Typography>
              }
              color="primary"
            >
              <InfoIcon className={classes.infoIcon} color="inherit" fontSize="inherit" />
            </Tooltip>
          </Typography>
        </MicroFormSection>
      </MicroFormLayout>
    </RhfForm>
  );
};
